export default [
  /*{
    header: 'Outils',
  },
  {
    title: 'Utilisateurs',
    route: 'apps-user',
    icon: 'UsersIcon',
  },
  {
    title: 'Documentation',
    route: 'apps-doc',
    icon: 'FileTextIcon',
  },
  {
    title: 'TESTZONE',
    route: 'apps-test',
    icon: 'SettingsIcon',
  },
  {
    title: 'SAMPLE',
    route: 'apps-sample',
    icon: 'SettingsIcon',
  },*/
]
