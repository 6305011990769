export default [
  {
    header: 'Modules',
  },
  {
    title: 'Produits',
    route: 'apps-product',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Prestataires',
    route: 'apps-provider',
    icon: 'UsersIcon',
  },
  {
    title: 'Packs',
    route: 'apps-pack',
    icon: 'PackageIcon',
  },
  {
    title: 'Categories',
    icon: 'ListIcon',
    route: 'apps-category',
  },
  {
    title: 'Utilisateurs',
    icon: 'UsersIcon',
    route: 'apps-client',
  },
  {
    title: 'News',
    icon: 'CastIcon',
    route: 'apps-post',
  },
  {
    title: 'Soins',
    icon: 'BoxIcon',
    route: 'apps-soin',
  },
  {
    header: 'Configuration',
  },
  {
    title: 'Administrateurs',
    icon: 'UserIcon',
    route: 'apps-user',
  },
]
